<template>
  <div class="card card-custom gutter-b">
    <v-overlay :value="carregando" :absolute="true">
      <v-progress-circular indeterminate size="32" color="#462181" width="2"
        ><v-progress-circular
          indeterminate
          size="24"
          color="#ff7f00"
          width="2"
          rotate="180"
        ></v-progress-circular
      ></v-progress-circular>
    </v-overlay>
    <b-modal
      ref="mdlDetalhes"
      title="Detalhes da Campanha"
      ok-title="Fechar"
      ok-variant="secondary"
      ok-only
      size="xl"
      scrollable
    >
      <b-table :fields="cabecalho" :items="linhas" hover small>
        <template #cell(acionada)="data">
          <p v-for="(v, k) in data.value" :key="k" class="mb-0">{{ v }}</p>
        </template>
        <template #cell(acoes)="data">
          <p v-for="(v, k) in data.value" :key="k" class="mb-0">{{ v }}</p>
        </template>
      </b-table>
    </b-modal>
    <div class="card-body p-0" style="position: relative">
      <div
        v-if="is_arrow_left_visible"
        id="SetaEsquerdaHoverable"
        @mouseover="hoveringSetaEsquerda"
        @mouseleave="leavingHoverSetaEsquerda"
        @click="clickSetinhaEsquerda"
      >
        <i class="fa fa-chevron-left"></i>
      </div>
      <div
        v-if="is_arrow_right_visible"
        id="SetaDireitaHoverable"
        @mouseover="hoveringSetaDireita"
        @mouseleave="leavingHoverSetaDireita"
        @click="clickSetinhaDireita"
      >
        <i class="fa fa-chevron-right"></i>
      </div>
      <div
        class="d-flex align-items-center justify-content-between card-spacer flex-grow-1"
      >
        <div class="d-flex flex-column w-100">
          <div class="d-flex align-items-center justify-content-between w-100">
            <div class="d-flex align-items-center w-50">
              <h5 class="m-0 mr-3">Percentual de Respostas</h5>
              <Tooltip
                message="Os gráficos representam os envios e respostas de diferentes tipos de campanhas. É importante notar que um mesmo usuário pode ter respondido mais de uma vez, o que pode resultar em um aumento na taxa de respostas. O centro do gráfico mostra o percentual de respostas de cada campanha."
              />
            </div>
            <slot></slot>
          </div>
          <br />
          <div
            class="d-flex pb-4"
            style="
              gap: 3rem;
              overflow: hidden;
              overflow-y: hidden !important;
              margin: 0 32px 0 32px !important;
              align-items: center;
            "
            :style="{
              'justify-content': dados.length <= 3 ? 'center' : 'flex-start'
            }"
            ref="PieChartContainer"
          >
            <CampanhaPieChart
              v-for="(card, k) in dados"
              :titulo="card.campanha"
              :enviado="card.enviado"
              :recebido="+card.recebido"
              :percentual="card.percentual"
              :key="'campanha-pie-chart-' + k"
              :card_id="card.id"
              @abrirDetalhesCampanhaEstatistica="detalhes"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CampanhaPieChart from "./CampanhaPieChart.vue";
import ApiService from "@/core/services/api.service";
import Tooltip from "./Tooltip.vue";

export default {
  props: {
    carregando: {
      type: Boolean,
      required: true
    },
    dados: {
      type: Array,
      required: true
    },
    periodo: {
      required: true
    },
    exibirCampanhasSemCallback: {
      type: Boolean,
      required: true
    }
  },
  components: {
    CampanhaPieChart,
    Tooltip
  },
  data() {
    return {
      arrow_checks_interval: null,
      is_arrow_left_visible: true,
      is_arrow_right_visible: true,
      scrolling_left_interval: null,
      scrolling_right_interval: null,
      chartOptions: {
        chart: {
          type: "donut",
          toolbar: {
            show: false
          }
        },
        labels: ["Envios", "Respostas"],
        dataLabels: {
          enabled: false
        },
        legend: {
          position: "bottom",
          horizontalAlign: "center"
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                value: {
                  show: true,
                  fontSize: "16px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  fontWeight: 400,
                  color: undefined,
                  offsetY: 16,
                  formatter: function (val) {
                    return val;
                  }
                },
                total: {
                  show: true,
                  showAlways: true,
                  label: "engajamento",
                  fontSize: "20px",
                  color: "#373d3f",
                  formatter: function (w) {
                    return w.globals.seriesTotals[0] + "%";
                  }
                }
              }
            }
          }
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              }
            }
          }
        ]
      },
      chartSeries: [44, 55],
      cabecalho: [
        {
          key: "campanha",
          label: "Modelo",
          sortable: false,
          class: "align-middle"
        },
        {
          key: "existe_callback",
          label: "Callback?",
          sortable: false,
          class: "align-middle text-center"
        },
        {
          key: "enviado",
          label: "Enviado",
          sortable: false,
          class: "align-middle text-center"
        },
        {
          key: "recebido",
          label: "Recebido",
          sortable: false,
          class: "align-middle text-center"
        },
        {
          key: "acionada",
          label: "Acionada",
          sortable: false,
          class: "align-middle text-center"
        },
        {
          key: "acoes",
          label: "Ações",
          sortable: false,
          class: "align-middle text-center"
        },
        {
          key: "percentual",
          label: "% Respostas",
          sortable: false,
          class: "align-middle text-center",
          formatter: this.formatarPercentual
        }
      ],
      linhas: []
    };
  },
  methods: {
    hoveringSetaEsquerda() {
      if (this.scrolling_left_interval)
        clearInterval(this.scrolling_left_interval);
      this.scrolling_left_interval = setInterval(() => {
        this.$refs.PieChartContainer.scrollLeft -= 5;
      }, 5);
    },
    leavingHoverSetaEsquerda() {
      clearInterval(this.scrolling_left_interval);
    },
    clickSetinhaEsquerda() {
      this.$refs.PieChartContainer.scrollLeft -= 100;
    },
    hoveringSetaDireita() {
      if (this.scrolling_right_interval)
        clearInterval(this.scrolling_right_interval);
      this.scrolling_right_interval = setInterval(() => {
        this.$refs.PieChartContainer.scrollLeft += 5;
      }, 5);
    },
    leavingHoverSetaDireita() {
      clearInterval(this.scrolling_right_interval);
    },
    clickSetinhaDireita() {
      this.$refs.PieChartContainer.scrollLeft += 100;
    },
    detalhes(id) {
      this.$refs.mdlDetalhes.show();
      ApiService.post("/dashboard/cards-campanha", {
        periodo: this.periodo,
        agrupamento: "modelo",
        campanha: id,
        exibirCampanhasSemCallback: this.exibirCampanhasSemCallback
      }).then((res) => {
        const dados = res.data.data;

        if (dados) {
          this.linhas = dados.flatMap((item) => {
            if (!item.possibilidades_resposta) {
              // Return the item as-is or handle differently if needed
              return [item];
            }
            return {
              ...item,
              acionada: Object.keys(item.possibilidades_resposta),
              acoes: Object.values(item.possibilidades_resposta)
            };
          });
        }

        this.$refs.mdlDetalhes.show();
      });
    },
    formatarPercentual(n) {
      return n.toFixed(1) + "%";
    }
  },

  mounted() {
    this.arrow_checks_interval = setInterval(() => {
      if (this.$refs.PieChartContainer) {
        if (
          this.$refs.PieChartContainer.scrollLeft ===
          this.$refs.PieChartContainer.scrollWidth -
            this.$refs.PieChartContainer.clientWidth
        ) {
          this.is_arrow_right_visible = false;
          clearInterval(this.scrolling_right_interval);
        } else {
          this.is_arrow_right_visible = true;
        }
        if (this.$refs.PieChartContainer.scrollLeft === 0) {
          this.is_arrow_left_visible = false;
          clearInterval(this.scrolling_left_interval);
        } else {
          this.is_arrow_left_visible = true;
        }
      }
    }, 200);
  },
  beforeDestroy() {
    clearInterval(this.arrow_checks_interval);
  }
};
</script>

<style lang="scss" scoped>
.card {
  transition: 0.3s ease;
  &:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
}
#SetaEsquerdaHoverable {
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  width: 36px;
  height: 36px;
  background: #472282;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 90;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  &:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  i {
    color: #fff;
    font-size: 1rem;
  }
}
#SetaDireitaHoverable {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  width: 36px;
  height: 36px;
  background: #472282;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 90;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  &:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  i {
    color: #fff;
    font-size: 1rem;
  }
}
</style>
